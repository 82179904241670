<template>
  <div class="newbill">
    <!-- 1.时间选择条件搜索框 -->
    <div class="flex-nowrap-space-between mb-20">
      <div class="flex-nowrap-space-around">
        <div class="mr-20 first-name">时间选择</div>
        <el-radio-group v-model="time" size="small" @change="setTimeData">
          <el-radio-button label="1">今日</el-radio-button>
          <el-radio-button label="2">昨天</el-radio-button>
          <el-radio-button label="3">本周</el-radio-button>
          <el-radio-button label="4">上周</el-radio-button>
          <!-- <el-radio-button label="5">本月</el-radio-button> -->
          <el-radio-button label="6">上月</el-radio-button>
        </el-radio-group>
        <div class="ml-20">
          <el-date-picker
            v-model="timeList"
            @change="setDataList"
            type="daterange"
            size="small"
            :clearable="false"
            value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabledDate="disabledDate"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="flex-nowrap-flex-start">
        <div class="ml-10">
          <el-button
            @click="exportDialogVisible = true"
            class="button-el"
            size="medium"
          >
            <icon-font
              type="icon-daochushuju"
              class="button-icon-font"
            />聚合导出
          </el-button>
        </div>
        <div class="ml-10">
          <el-dropdown>
            <el-button class="button-el" size="medium">
              <icon-font type="icon-daochushuju" class="button-icon-font" />
              导出数据
              <icon-font type="icon-copy-4-36" class="button-icon-font" />
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <!-- <el-dropdown-item @click="setSearchExport(0)">全部</el-dropdown-item> -->
                <!-- <el-dropdown-item @click="setSearchExport(1)">选定</el-dropdown-item> -->
                <el-dropdown-item @click="setSearchExport(2)"
                  >搜索结果</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
          class="font-refresh-out flex-nowrap-center-center ml-10"
          @click="refreshTable"
        >
          <icon-font
            v-if="loading == false"
            type="icon-shuaxin"
            class="font-refresh"
          />
          <i v-else class="el-icon-loading loding-color"></i>
        </div>
      </div>
    </div>
    <!-- 2.数据维度条件搜索框 -->
    <div class="" style="display: felx; flex-direction: column">
      <div class="flex" style="margin-bottom: 15px">
        <div class="mr-20 first-name">数据维度</div>
        <el-radio-group v-model="type" size="small" @change="setTypeData">
          <el-radio-button label="amount">
            <span class="title-span">服务商</span>
          </el-radio-button>
          <el-radio-button label="user">
            <span class="title-span">用户</span>
          </el-radio-button>
          <el-radio-button label="number">
            <span class="title-span">小号</span>
          </el-radio-button>
          <el-radio-button label="interfaces">
            <span class="title-span">线路</span>
          </el-radio-button>
          <el-radio-button label="telA">
            <span class="title-span">主叫</span>
          </el-radio-button>
        </el-radio-group>
      </div>




      <div class="ml-20" style="margin-bottom: 15px;"  v-if="type == 'telA'">
       
       <div :style="{display:'flex'}">
         <el-select v-model="searchFrom.amountId" placeholder="请选择查询的服务商" style="width: 300px" size="small"
           @change="setDataList" clearable filterable>
           <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id"></el-option>
         </el-select>
         <el-input size="small" style="width: 300px;margin-left: 10px;" v-model.trim="searchFrom.agentName" clearable
           placeholder="请输入渠道用户" class="input-with-select">
           <template #append>
             <el-button @click="setDataList">搜索</el-button>
           </template>
         </el-input>
         <el-input size="small" style="width: 300px;margin-left: 10px "  v-model.trim="searchFrom.mobile" clearable
           placeholder="请输入主叫号码" class="input-with-select">
           <template #append>
             <el-button @click="setDataList">搜索</el-button>
           </template>
         </el-input>
       </div>
     </div>
     
      <a-form ref="formRef" layout="horizontal" :label-col="labelCol"  :model="searchFrom" v-else>
        <a-row :gutter="24" align="middle">
          <a-col :span="20">
            <a-row :gutter="24">
                <a-col :span="5" v-if="type !== 'telA'">
                <a-form-item label="模式" name="mode" >
                  <el-select
                    v-model="searchFrom.mode"
                    placeholder="请选择查询的模式"
                    style="width: 100%"
                    size="small"
                    @change="setDataList"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in mode_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="服务商" name="amountId">
                  <el-select
                    v-model="searchFrom.amountId"
                    placeholder="请选择查询的服务商"
                    style="width: 100%"
                    size="small"
                    @change="setDataList"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="公司" name="uid">
                  <el-select
                    v-model="searchFrom.uid"
                    placeholder="请选择查询的公司"
                    style="width: 100%"
                    size="small"
                    @change="setDataList"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="`${item.company}`"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1 ? 'no-red' : ''">
                          <span>{{ item.company }}</span>
                          <!-- <span class="ml-5">(Id:{{ item.uid }})</span> -->
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </a-form-item>
              </a-col>
               <a-col :span="5"  v-if="type=='user'">
                <a-form-item label="销售" name="suid">
                   <el-select v-model="searchFrom.inviteUserName" placeholder="请选择" style="width: 100%" clearable filterable  size="small"  @change="setDataList">
                  <el-option v-for="(item, index) in userQuerydata1" :key="index" :label="item.label" :value="item.value">
                    <div class="flex-nowrap-space-between">
                      <div>
                        <span>{{ item.value }}</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="客服" name="suid">
                  <el-select
                    v-model="searchFrom.suid"
                    placeholder="请选择查询的客服"
                    style="width: 100%"
                    size="small"
                    @change="setDataList"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in user_list1"
                      :key="index"
                      :label="`${item.nickname}`"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div>
                          <span>{{ item.nickname }}</span>
                          <!-- <span class="ml-5">(Id:{{ item.uid }})</span> -->
                        </div>
                      </div>
                    </el-option>
                  </el-select>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="小号" name="phone"  >
                  <el-input
                    size="small"
                    v-model.trim="searchFrom.phone"
                    clearable
                    placeholder="请输入查询的小号"
                    class="input-with-select"
                  >
                  </el-input>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="线路" name="interfaceId">
                  <el-select
                    size="small"
                    v-model="searchFrom.interfaceId"
                    placeholder="请选择查询的线路"
                    style="width: 100%"
                    @change="setDataList"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in interface_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </a-form-item>
              </a-col>
               <a-col :span="5">
                <a-form-item label="用户">
                  <el-select
                    size="small"
                    v-model="searchFrom.customerTypeList"
                    placeholder="请选择查询的用户"
                    style="width: 100%"
                    @change="setDataList"
                    clearable
                    filterable
                    multiple
                  >
                    <el-option
                      v-for="(item, index) in interface_customerTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="4">
            <a-row>
              <a-col :span="24" :style="{ textAlign: 'right' }">
                <a-button type="primary" @click="setDataList"> 查询</a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                  重置</a-button
                >
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 3.汇总 -->
    <div>
      <el-table
        class="eltable"
        v-loading="loading"
        ref="multipleTable"
        size="mini"
        :data="countList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
        row-key="id"
        :max-height="tableHeight"
        default-expand-all
        :row-style="{ height: '60px' }"
        :cell-style="{ padding: '0px' }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column label="汇总">
          <template #default="">{{ type == "telA" ? total : "" }}</template>
        </el-table-column>
        <el-table-column
          v-if="type == 'amount' && type !== 'telA'"
          label="服务商(数量)"
        >
          <template #default="scope">{{ scope.row.amountCount }}</template>
        </el-table-column>
        <el-table-column
          v-if="type == 'user' && type !== 'telA'"
          label="用户(数量)"
        >
          <template #default="scope">{{ scope.row.userCount }}</template>
        </el-table-column>
        <el-table-column
          v-if="type == 'number' && type !== 'telA'"
          label="小号(数量)"
        >
          <template #default="scope">{{ scope.row.numberCount }}</template>
        </el-table-column>
        <el-table-column
          v-if="type == 'interfaces' && type !== 'telA'"
          label="线路(数量)"
        >
          <template #default="scope">{{ scope.row.interfaceCount }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="呼叫次数">
          <template #default="scope">{{ scope.row.billNum }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="接听次数">
          <template #default="scope">{{ scope.row.callNum }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="服务商总数">
          <template #default="scope">{{ scope.row.amountCount }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="渠道用户总数">
          <template #default="scope">{{ scope.row.agentCount }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="分钟数(分钟)">
          <template #default="scope">{{ scope.row.sumCallTime }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="通话费(元)">
          <template #default="scope">{{ scope.row.sumCallCharge }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="分钟数(分钟)">
          <template #default="scope">{{ scope.row.callTime }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="通话费(元)">
          <template #default="scope">{{ scope.row.callCharge }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="取整分钟/取整利润">
          <template #default="scope">{{
            parseInt(scope.row.callTime) -
            parseInt(scope.row.originalMinutes) +
            "/" +
            scope.row.roundPrice
          }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="成本通话费(元)">
          <template #default="scope">{{ scope.row.costPrice }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          prop="callProfit"
          fixed="right"
          label="利润(元)"
        >
          <template #default="scope">{{ scope.row.callProfit }}</template>
        </el-table-column>
        <el-table-column
          #default="scope"
          v-if="type !== 'telA'"
          fixed="right"
          label="查看详情"
          width="200px"
        >
          <a @click="checkDetail(scope.row)">查看详情</a>
        </el-table-column>
      </el-table>
    </div>
    <!-- 4.数据表格 -->
    <div>
      <el-table
        class="eltable"
        v-loading="loading"
        ref="multipleTable"
        size="mini"
        :data="downTable"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
        row-key="id"
        :max-height="tableHeight"
        default-expand-all
        @sort-change="changeTableSort"
        :row-style="{ height: '50px' }"
        :cell-style="{ padding: '0px' }"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          fixed
          v-if="type == 'user' && type !== 'telA'"
          label="昵称"
        >
          <template #default="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column fixed label="企业名称">
          <template #default="scope">{{
            scope.row.company ? scope.row.company : "-"
          }}</template>
        </el-table-column>
        <el-table-column fixed v-if="type == 'user'"  label="所属销售">
          <template #default="scope">{{
            scope.row.inviteUserName ? scope.row.inviteUserName : "-"
          }}</template>
        </el-table-column>
        <el-table-column fixed v-if="type == 'user'" label="所属客服">
          <template #default="scope">{{
            scope.row.sysName ? scope.row.sysName : "-"
          }}</template>
        </el-table-column>
        <el-table-column
          fixed
          v-if="type == 'number' && type !== 'telA'"
          label="小号"
        >
          <template #default="scope">{{ scope.row.number }}</template>
        </el-table-column>
        <el-table-column
          fixed
          v-if="(type == 'amount' || type == 'number') && type !== 'telA'"
          label="服务商"
        >
          <template #default="scope">{{ scope.row.amountName }}</template>
        </el-table-column>
        <el-table-column
          fixed
          v-if="type == 'interfaces' && type !== 'telA'"
          label="线路"
        >
          <template #default="scope">{{ scope.row.interfaceName }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          fixed
          prop="date"
          :sortable="'custom'"
          label="日期 "
        >
          <template #default="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          prop="billNum"
          :sortable="'custom'"
          label="呼叫次数"
        >
          <template #default="scope">{{ scope.row.billNum }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          prop="callNum"
          :sortable="'custom'"
          label="接听次数"
        >
          <template #default="scope">{{ scope.row.callNum }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="主叫号码">
          <template #default="scope">{{ scope.row.num }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="所属服务商">
          <template #default="scope">{{ scope.row.amountName }}</template>
        </el-table-column>
        <el-table-column v-if="type == 'telA'" label="所属用户渠道">
          <template #default="scope">{{ scope.row.agentName }}</template>
        </el-table-column>
        <el-table-column
          prop="callTime"
          :sortable="'custom'"
          label="分钟数(分钟)"
        >
          <template #default="scope">{{ scope.row.callTime }}</template>
        </el-table-column>
        <el-table-column
          prop="callCharge"
          :sortable="'custom'"
          label="通话费(元)"
        >
          <template #default="scope">{{ scope.row.callCharge }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          prop="costPrice"
          :sortable="'custom'"
          label="取整分钟/取整利润"
        >
          <template #default="scope">{{
            parseInt(scope.row.callTime) -
            parseInt(scope.row.originalMinutes) +
            "/" +
            scope.row.roundPrice
          }}</template>
        </el-table-column>
        <el-table-column v-if="type !== 'telA'" label="成本通话费(元)">
          <template #default="scope">{{ +scope.row.costPrice }}</template>
        </el-table-column>
        <el-table-column
          v-if="type !== 'telA'"
          prop="callProfit"
          fixed="right"
          label="利润(元)"
        >
          <template #default="scope">{{ scope.row.callProfit }}</template>
        </el-table-column>
        <el-table-column
          #default="scope"
          v-if="type == 'amount' || type == 'user'"
          fixed="right"
          width="200px"
          label="查看详情"
        >
          <!-- <a v-if="type == 'amount'" @click="checkDetail(scope.row)"
            >查看详情</a
          > -->
          <a style="marginleft: 10px" @click="checkUserDetail(scope.row)">{{
            type == "amount" ? "查看用户详情" : "查看线路详情"
          }}</a>
        </el-table-column>
      </el-table>
    </div>
    <!-- 5.分页器 -->
    <div class="flex-nowrap-flex-end pagination-box">
      <el-pagination
        v-model="searchFrom.page"
        background
        :page-sizes="[10, 50, 100, 200]"
        :page-size="searchFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 聚合导出模态框 -->
    <el-dialog
      title="聚合导出"
      v-model="exportDialogVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
    >
      <div class="">
        <el-form
          label-position="left"
          label-width="130px"
          size="small"
          :model="exportForm"
          :rules="rules"
          ref="exportForm"
        >
          <div class="form-out-box p-0-30">
            <el-form-item label="服务商：">
              <el-select
                v-model="exportForm.amountId"
                filterable
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="(item, index) in amount_id"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接口类型：" prop="interfaceId">
              <el-select
                v-model="exportForm.interfaceId"
                placeholder="请选择"
                filterable
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="(item, index) in interface_id"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始日期：" prop="pickerDate">
              <el-date-picker
                @change="selectDate"
                v-model="pickerDate"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
                value-format="YYYY-MM-DD"
                :default-time="pickerDate"
                size="mini"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="大于分钟数：" prop="callTimeBegin">
              <el-input
                v-model.trim="exportForm.callTimeBegin"
                placeholder="请输入大于分钟数"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小于分钟数：" prop="callTimeEnd">
              <el-input
                v-model.trim="exportForm.callTimeEnd"
                placeholder="请输入小于分钟数"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="exportReset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="hanldeExport" round>
                确认
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userNumberCount from "@/api/open/userNumberCount/userNumberCount";
import { getDate, getMonday, getMonth } from "@/utils/timeToChoose.js";
import amountts from "@/api/open/privacy/amount";
import interfacets from "@/api/open/privacy/interface";
import userts from "@/api/web/user";
import { IconFont } from "@/utils/iconfont";
import { ElMessage } from "element-plus";
import { message } from "ant-design-vue";
import userApp from "@/api/web/userApp";
export default {
  name: "Newbill",
  components: {
    IconFont,
  },
  data() {
    const validateMinutes = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("分钟数输入格式有误，请不要输入除数字外的其他字符"));
      } else {
        callback();
      }
    };
    return {
      formState: {},
      labelCol: { span: 4 },
      exportDialogVisible: false, //聚合导出
      detailVisible: true, //详情按钮显示
      time: "1",
      total: 0,
      type: "amount",
      timeList: [],
      loading: false,
      countList: [],
      downTable: [],
      //聚合导出条件
      exportForm: {
        amountId: "", //服务商id
        interfaceId: "", //接口id
        dateBegin: "",
        dateEnd: "",
        callTimeBegin: "", //大于分钟数
        callTimeEnd: "", //小于分钟数
      },
      pickerDate: [getDate(0), getDate(0)], //选中时间段--创建时间
      //搜索查询条件
      searchFrom: {
        customerTypeList:[],
        page: 1,
        pageSize: 10,
        agentName: "",
        mobile: "", //查询主叫
        amountId: "",
        mode:"",
        phone: "",
        interfaceId: "",
        uid: "",
        groupBy: ["amount"],
        sortBy: ["date"],
        sortDesc: [true],
        dateBegin: "",
        dateEnd: "",
        suid: "",
        inviteUserName:'',
      },
      userQuerydata1:[
        {
        lable:'陈金雅',
        value:'陈金雅'
        },
        {
        lable:'陈金雅(增量)',
        value:'陈金雅(增量)'
        },
        {
        lable:'刘霞',
        value:'刘霞'
        },
        {
        lable:'刘霞(增量)',
        value:'刘霞(增量)'
        },
        {
        lable:'黄友',
        value:'黄友'
        },
        {
        lable:'徐康',
        value:'徐康'
        },
        {
        lable:'杭圣寅',
        value:'杭圣寅'
        },
        {
        lable:'张倩倩',
        value:'张倩倩'
        },
        {
        lable:'张艳',
        value:'张艳'
        },
        {
        lable:'贾凯',
        value:'贾凯'
        },
        {
        lable:'贾凯(增量)',
        value:'贾凯(增量)'
        },
        {
        lable:'徐瑞坚',
        value:'徐瑞坚'
        },
        {
        lable:'徐瑞坚(增量)',
        value:'徐瑞坚(增量)'
        },
        {
        lable:'余勇',
        value:'余勇'
        },
        {
        lable:'张可',
        value:'张可'
        },
        {
        lable:'张可(增量)',
        value:'张可(增量)'
        },
        {
        lable:'颜明',
        value:'颜明'
        },
        {
        lable:'颜明(增量)',
        value:'颜明(增量)'
        },
        {
        lable:'于总',
        value:'于总'
        },
         {
        lable:'张总',
        value:'张总'
        },
      ],
      userChannel_id: [], //用户渠道
      amount_id: [], // 拿到的通信账户类型
      mode_id:[{
        id:0,
        name:'AXB模式'
      },{
        id:1,
        name:'回拨模式'
      },
      {
        id:2,
        name:'SIP线路'
      },{
        id:3,
        name:'资料审核'
      }],//模式下拉
      interface_id: [], // 拿到的接口类型
      interface_customerTypeList:[
          {
           name:'API用户(数字云结算)',
           id:0
              },{
           name:'服务商用户(服务商结算)',
           id:1
      },{
           name:'服务商用户(数字云结算)',
           id:2
      },
      // {
      //      name:'分钟王用户(数字云结算)',
      //      id:3
      // },
      {
           name:'API用户(非数字云结算)',
           id:4
      },
      // {
      //      name:'分钟王用户(非数字云结算)',
      //      id:5
      // }
      ],
      user_list: [], // 用户的集合
      user_list1: [], //客服的集合
      tableHeight: 540,
      defaultTime: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ], // '12:00:00', '08:00:00'
      rules: {
        pickerDate: [
          {
            type: "date",
            required: false,
          },
        ],
        callTimeBegin: [
          { required: false, trigger: "blur", validator: validateMinutes },
        ],
        callTimeEnd: [
          { required: false, trigger: "blur", validator: validateMinutes },
        ],
      },
    };
  },
  watch: {
    // type(news,old){
    //     if( news == 'user' ){
    //         this.tableHeight = 540
    //     }else{
    //         this.tableHeight = 640
    //     }
    // }
  },
  mounted() {
    this.exportForm.dateBegin = this.pickerDate[0];
    this.exportForm.dateEnd = this.pickerDate[1];
    this.setTimeData();
    this.getDownLowData();
    this.getuserQuery();
  },
  methods: {
    disabledDate(time) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 
    return time.getTime() >= today.getTime();
  },
    handelReset(){
this.$refs.formRef. resetFields()
this.searchFrom.inviteUserName = "";
this.searchFrom.customerTypeList=[]
this.setDataList()
    },
    // 获取下拉数据
    getuserQuery() {
      userApp.userQuery({}).then((res) => {
        this.user_list1 = res.data;
        // console.log(res,'ooooooooooooooooooooo');
      });
    },
    getDownLowData() {
      // 获取通信账户类型
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
      // 获取接口类型数组
      interfacets.getInterface({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    checkUserDetail(row) {
      if (this.type == "amount") {
        sessionStorage.setItem("amountId", row.amountId);
        // sessionStorage.setItem('amountId',row.amountId  )
      } else {
        sessionStorage.setItem("amountId", row.uid);
      }

    console.log(row,this.type);
      this.$router.push({
        path: "/open/userDetail",
        query: {
          type: this.type,
          id: this.type == "amount" ? row.amountId : row.uid,
          dateBegin: this.searchFrom.dateBegin,
          dateEnd: this.searchFrom.dateEnd,
          data: this.type == "amount" ? row.amountName : row.company,
        },
      });
    },
    // 查看详情跳转传参
    checkDetail(scope) {
      if (!scope.amountId) {
        this.$router.push({
          path: "/open/ChartDetail",
          query: {
            dateBegin: this.searchFrom.dateBegin,
            dateEnd: this.searchFrom.dateEnd,
          },
        });
      } else {
        this.$router.push({
          path: "/open/Trend",
          query: {
            amountId: scope.amountId,
            amountName: scope.amountName
              ? scope.amountName
              : scope.interfaceName,
            dateBegin: this.searchFrom.dateBegin,
            dateEnd: this.searchFrom.dateEnd,
          },
        });
      }
    },
    //主体列表数据
    getDownList(loading) {
      this.loading = loading;
      userNumberCount
        .accountList({
          ...this.searchFrom,
        })
        .then((res) => {
          // console.log(res,'pppppppppppppp');
          if (this.type == "amount") {
            res.data.count.amountCount == 0 ||
            res.data.count.amountCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "user") {
            res.data.count.userCount == 0 ||
            res.data.count.userCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "number") {
            res.data.count.numberCount == 0 ||
            res.data.count.numberCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          if (this.type == "interfaces") {
            res.data.count.interfaceCount == 0 ||
            res.data.count.interfaceCount == undefined
              ? (this.countList = [])
              : (this.countList = [res.data.count]);
          }
          console.log(res, "--------------");
          this.downTable = res.data.page.records;
          this.total = res.data.page.total;
          this.loading = false;
        });
    },
    //主叫单表数据
    getTelAList() {
      userNumberCount
        .accountCallingList({
          ...this.searchFrom,
        })
        .then((res) => {
          if (res.code !== 200) {
            this.$message.error(res.message);
            return false;
          } else {
            this.countList = res.data.count ? [res.data.count] : [];
            this.downTable = res.data.page.records;
            this.total = res.data.page.total;
          }
        });
    },
    // 重置聚合导出
    exportReset() {
      this.exportForm = {
        amountId: "",
        interfaceId: "",
        dateBegin: "",
        dateEnd: "",
        callTimeBegin: "",
        callTimeEnd: "",
      };
      this.$refs.exportForm.resetFields();
      this.pickerDate = [];
    },
    // 确认聚合导出
    hanldeExport() {
      this.$refs.exportForm.validate((valid) => {
        if (!valid) {
          return;
        } else {
          userNumberCount.groupExport({ ...this.exportForm }).then((res) => {
            if (res.code != 200) {
              return message.error(res.message);
            } else {
              this.exportDialogVisible = false;
              this.$refs.exportForm.resetFields();
              this.pickerDate = [getDate(0), getDate(0)];
              message.success(res.data);
            }
          });
        }
      });
    },
    //选择日期
    selectDate(val) {
      if (val) {
        this.exportForm.dateBegin = val[0];
        this.exportForm.dateEnd = val[1];
      } else {
        this.exportForm.dateBegin = "";
        this.exportForm.dateEnd = "";
      }
    },
    // 刷新
    refreshTable() {
      this.searchFrom.page = 1;
      this.searchFrom.sortBy = ["date"];
      this.searchFrom.sortDesc = [true];
      this.time = "1";
      this.setTimeData();
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val;
      if (this.type == "telA") {
        this.getTelAList();
      } else {
        this.getDownList(true);
      }
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val;
      if (this.type == "telA") {
        this.getTelAList();
      } else {
        this.getDownList(true);
      }
    },
    // 日期选择器获取值
    setDataList() {
      this.timeList == null ? (this.timeList = []) : "";
      this.time = null;
      this.searchFrom.dateBegin = this.timeList[0];
      this.searchFrom.dateEnd = this.timeList[1];
      if (this.type == "telA") {
        this.getTelAList();
      } else {
        this.getDownList(true);
      }
    },
    // 设置日期时间
    setTimeData() {
      let listDate = this.setDate(this.time);
      this.timeList = this.setDate(this.time);
      this.searchFrom.dateBegin = listDate[0];
      this.searchFrom.dateEnd = listDate[1];
      if (this.type == "telA") {
        this.getTelAList();
      } else {
        this.getDownList(true);
      }
    },
    // 获取维度
    setTypeData() {
      this.searchFrom.amountId = "";
       this.searchFrom.mode = "";
      this.searchFrom.uid = "";
      this.searchFrom.interfaceId = "";
      this.searchFrom.phone = "";
       this.searchFrom.customerTypeList = [];
      if (this.type == "telA") {
        this.getTelAList();
        return false;
      }
      this.searchFrom.groupBy = [this.type];
      this.getDownList(true);
    },
    // 文件导出
    setSearchExport() {
      if (this.type == "telA") {
        userNumberCount
          .accountCallingExport({
            ...this.searchFrom,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
            message.success(res.data);
          });
      } else {
        userNumberCount
          .exportList({
            ...this.searchFrom,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
            message.success(res.data);
          });
      }
    },
    // 获取日期时间段
    setDate(type) {
      switch (type) {
        case "1":
          return [getDate(0), getDate(0)];
        case "2":
          return [getDate(-1), getDate(-1)];
        case "3":
          return [getMonday("s", 0), getMonday("e", 0)];
        case "4":
          return [getMonday("s", -1), getMonday("e", -1)];
        case "5":
          return [getMonth("s", 0), getMonth("e", 0)];
        case "6":
          return [getMonth("s", -1), getMonth("e", -1)];
        default:
          return [];
      }
    },
    // 表格排序
    changeTableSort(column) {
      if (column.prop == null) {
        ElMessage({
          showClose: true,
          message: "当前已是向下排序",
          type: "warning",
        });
      } else {
        this.searchFrom.sortBy = [column.prop];
        column.order == "ascending"
          ? (this.searchFrom.sortDesc = [false])
          : (this.searchFrom.sortDesc = [true]);
        if (this.type == "telA") {
          this.getTelAList();
        } else {
          this.getDownList(true);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.newbill {
  padding-right: 1.125rem;
}
.first-name {
  font-size: 14px;
  font-weight: 400;
  color: #131523;
}
.pagination-box {
  height: 60px;
}
.title-span {
  display: inline-block;
  width: 80px;
}
</style>
